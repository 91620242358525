

/* Importing Bootstrap SCSS file. */
$primary: #5223CA;
$secondary: #FF2F8E;
$warning:  #ffc107;
$linear-background: linear-gradient(to right,#5223CA, #FF2F8E);
$dark-linear-background: linear-gradient(to right, #451daf, #e5297f);
$dark: #300a33;
$primary-background: rgb(254, 227, 185);
$secondary-background: rgb(204, 204, 204);

$sucess: #28A745FF;
$modal-border-radius: 30px;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "dark":       $dark,
  "success":    $sucess,
  "warning":    $warning,
  //"success":    $success,
  //"info":       $info,
  //"danger":     $danger,
  //"light":      $light,
);






