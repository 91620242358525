/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap');

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "theme";
@import '~bootstrap/scss/bootstrap';
@import '~ng-snotify/styles/simple';
@import "./assets/scss/ng-select";

html, body {
  height: 100%;
  font-family: Roboto, sans-serif;
}

::-webkit-input-placeholder { /* Edge */
  color: #d9d9d9 !important;
  text-transform: uppercase;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #d9d9d9 !important;
  text-transform: uppercase;
}

::placeholder {
  color: #d9d9d9 !important;
  text-transform: uppercase;
}

.icon-primary {
  fill: $primary !important;
}

.icon-secondary {
  fill: $secondary !important;
}

.icon-success {
  fill: $sucess !important;
}

.icon-danger {
  fill: $danger !important;
}

.icon-warning {
  fill: #FFC107 !important;
}

.icon-white {
  fill: #fff !important;
}

.icon-light {
  fill: #E5E6EC;
}

.icon-grey {
  fill: #777D93;
}

button.disabled svg-icon {
  fill: #c5c5c5;
}

svg-icon svg {
  height: 18px;
  width: 18px;
}

svg-icon.icon-xs svg {
  height: 12px;
  width: 12px;
}

svg-icon.icon-sm svg {
  height: 15px;
  width: 15px;
}

svg-icon.icon-lg svg {
  height: 22px;
  width: 22px;
}

svg-icon.icon-xl svg {
  height: 36px;
  width: 36px;
}

svg-icon.icon-xxl svg {
  height: 72px;
  width: 72px;
}


.snotify-confirm {
  background-color: $primary;

  .snotifyToast__buttons, .snotifyToast__buttons button {
    border-color: rgb(164 164 164) !important;
  }
}

.label-logon {
  text-transform: uppercase;
  font-size: 85%;
  padding-left: 12px;
  @extend .text-muted, .font-weight-bold, .mb-1;
}


.card-logon {
  text-align: center;
  height: 100%;
  border-radius: 20px;
  border: 0;
  @extend .shadow;


  .card-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    &.card-header-primary {
      @extend .bg-primary, .font-weight-bold, .text-white;
    }

    h1, h2, h3, h4, h5, p {
      margin: 0;
      font-weight: bold
    }
  }

  &:not(.card-sm) .card-header {
    @extend .py-4
  }

  &.card-sm {
    .card-header {
      @extend .p-2
    }

    .card-body {
      @extend .p-2
    }
  }

  .btn-bottom {
    height: 45px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.carousel-black-controls {
  .carousel-control-next,
  .carousel-control-prev, .carousel-indicators {
    filter: invert(100%);
  }
}

.form-display-errors .ng-pristine.ng-invalid.ng-touched {
  @extend .is-invalid;
}


@media (max-width: 575px) {
  .lang {
    width: 96px;
  }
  .modal-dialog {
    height: 100vh;
    position: absolute;
    bottom: 0px;
    padding: 0px !important;
    margin: 0px !important;
  }
  .modal-content {
    width: 100vw;
    min-height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
    border-radius: 30px 30px 0px 0px !important;
    overflow-y: scroll;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .modal-dialog {
    margin-top: 70px !important;
  }
}

.modal-dialog {
  margin: 20px auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  .modal-dialog {
    max-height: calc(100vh - 90px);
  }
}

@media (max-width: 479px) {
  .modal-content {
    min-width: 1px !important;
  }
}

.form-control.ng-select {
  border: none;
  padding: 0;
}

ng-select ng-dropdown-panel {
  text-align: left
}

ng-select.ng-select-disabled {
  background: #e5e5e5
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: transparent !important;
}

.btn-lg {
  font-size: 1.1em !important;
}


.nav-container {
  height: 100vh;
}


.user-nav {
  height: 50px;
  background-color: white;
  margin: 0;
  padding: 10px;

}

body {
  background-color: #F2F3F6
}

.content {
  float: right;
  position: relative;
}


#wrapper {
  padding-left: 250px;
  transition: all 0.4s ease 0s;
  height: 100%;
}

#sidebar-wrapper {
  margin-left: -250px;
  left: 250px;
  width: 250px;
  background: $primary;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.4s ease 0s;
}

.page-content-wrapper {
  width: 100%;
  position: relative;
  height: 100%;
}

.container-100 {
  height: 100%;
  margin-top: -50px;
  padding-top: 50px;

  & > .scrollable-container {
    height: 100%;
    overflow-y: scroll;
  }
}

.sidebar-nav {
  top: 0;
  width: 250px;
  list-style: none;
  margin: 0;
  padding: 0;
}


.hori {
  width: 80%;
}

.btn-hover:hover {
  background: linear-gradient(to left, #5223CA, #FF2F8E);
}

.btn-logon {
  background: $linear-background;
  border: none;
  border-radius: 7px;
  transition: 0.3s;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;

  &.btn-lg {
    font-size: 0.9rem !important;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
  }


  &:hover {
    color: #fff;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: #fff;
    background: $dark-linear-background !important;
  }
}

.table-logon {
  thead tr {
    color: #777D93;
    font-size: 80%;
  }

  & > tbody > tr > th:first-child {
    background: #777D93;
  }

  tbody {
    tr {
      border-radius: 20px;
      border-bottom: 1px solid #1c2123;
      border-top: 1px solid #1c2123;

      td:not(:last-child) {
        border-right: 1px solid #e1e1e1;
      }
    }
  }

  &.table-borderless tbody tr td {
    border: none
  }
}


.table td {
  background: white;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}


.th-key-bg {
  background: #777D93;
}

.table tr td:nth-child(9) {
  background: $primary;
}

.menu-icon {
  background-color: white;
  width: 50px;
  height: 30px;
  margin-top: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.apps-icon {
  background-color: #F2F3F6;
  width: 50px;
  height: 30px;
  margin-top: 3px;
  border: 2px solid white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border-bottom: 1px solid $primary;
}


.job-id {
  color: white;
  width: 70px;
  height: 25px;
  font-size: 0.7em;
  border-radius: 5px;
}


.nav-tabs li a {
  color: #aaa;
  padding-left: 30px;
  padding-right: 30px;
}


.salary-text {
  color: #aaa;
}


.box-media {
  background-color: white;
}

.box-media:hover {
  background-color: #aaa;
}

.btn-outline-primary:hover {
  background-color: white;
  color: $primary;
}


.applicant th {
  background-color: white;
}


.primary-bg-green {
  background-color: #0EC922;
}


.primary-color-green {
  background-color: #0EC922;
}

.bg-grey {
  background-color: #777D93;
}


tr td th {
  margin: 0 !important;
}

.payment label {
  font-size: 12px;
  font-weight: bold;
  color: #777D93;
}


.payment input {
  color: #777D93;
}

@media (min-width: 768px) and (max-width: 991px) {
  .modal-dialog.modal-xl {
    max-width: 728px;
  }
}

.modal-content {
  background-color: #F2F3F6;
  border-radius: $modal-border-radius;
  min-width: 410px;

  .modal-header {
    background: linear-gradient(to right, #5223CA, #FF2F8E);
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
  }

  .upload-content {
    border-bottom-left-radius: $modal-border-radius;
  }
}


.form-control:not(.form-control-border) {
  border: none;

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
}

.input-group-prepend button {
  border: none
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


.credit-card-img img {
  margin-top: 10px;
  margin-left: 10px;
}


.card-header {
  background-color: white;
}

.accordion .card {
  margin-top: 20px; /* solution with margins */
  box-shadow: 0 0.300rem 1rem rgb(0 0 0 / 18%) !important;
  border-radius: 10px 10px 10px 10px;
}

.accordion .card {
  border-radius: 10px 10px 10px 10px;

}

.accordion > .card:not(:first-of-type) {
  border-radius: 10px 10px 10px 10px;

}

.accordion > .card:not(:last-of-type) {
  border-radius: 10px 10px 10px 10px;
}

//.accordion .card{
//  height:50px;
//}

//.accordion .card button {
//  margin-top: -8px;
//}

.accordion .card .btn-link:focus, .btn-link.focus {
  text-decoration: none;
  border: none;
}


.accordion .card .btn-link:hover, .btn-link.focus {
  text-decoration: none;
}


span.card-header-link {
  width: 100% !important;
}


ngb-accordion .card-header > button {
  text-align: left;
  width: 100%;
  height: 100%;
}

.benefits {
  height: 100px;
  width: 110px;
  margin-left: 15px;
  border-radius: 10px;
  padding-bottom: 70px;
  margin-top: 40px;
}

.vl {
  border-left: 1px solid #777D93;
  height: 200px;
}

.link-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.link-wrapper:after {
  content: "";
  position: absolute;
  top: 70%;
  left: 10px;
  height: 1px;
  width: 23px;
  background: #777D93;
}


.link-wrapper img {
  display: block;
  position: relative;
  left: -13px;
  top: 10px;
}

.bg-logon-light {
  background-color: #F2F3F6;
}

.btn-license-req {
  width: 180px;
  color: white;
  background-color: #CACDD8;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 7px;

  &:hover {
    color: white;
    background-color: #5223CA;
  }
}


.bg-answer {
  background-color: #1B094A;
  color: white;
}


.switch {
  border: unset !important;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15.5px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
  }
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0;
  bottom: 0;
  background-color: #EDEEF2;
  -webkit-transition: .4s;
  transition: .4s;


}

input:checked + .slider {
  background-color: #FF2F8E;
}


input:focus + .slider {
  box-shadow: 0 0 1px;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.glasses img {
  margin-top: 30px;
}


.edit_img {
  position: absolute;
  z-index: 1;
  margin-top: 1px;
  margin-left: -23px;
  display: inline;
  width: 25px;
  height: 25px;

  &:hover {
    background-color: #FF2F8E;
  }
}

.media_img:hover > .edit_img {
  background-color: #FF2F8E;
}

.edit_picture {
  position: absolute;
  z-index: 1;
  margin-top: -10px;
  margin-left: -10px;
  display: inline;
  width: 25px;
  height: 25px;

  &:hover {
    background-color: #FF2F8E;
  }
}


.accordion [role="tab"] .btn {
  box-shadow: none !important;
  transition: none;
}

.btn-icon-inside-input {
  border: 1px solid #ced4da;
  border-right: 0;
}


button .close {
  border: none;
}

.modal-header > button {
  border: none;
}

.close, .btn:focus {
  outline: none !important;
}

.alert-danger {
  background-color: #9e28b0;
  color: white;
}

.drop-zone-class {
  border: none;
}

.remove-caret {
  height: 25px;
  width: 25px;
  border: 2px solid #d9534f;
  transform: rotate(90deg);
}

.remove-caret:after {
  display: none !important;
}

.auth-layout .form-control {
  border: none;
  background-color: #f3f3f3;
}

.spinner-size {
  width: 1.2rem;
  height: 1.2rem;
}

.primary-accordion > .card.active > .card-header {
  background-color: $primary;

  .btn {
    color: white
  }

  .arrow {
    transform: rotate(180deg);
    transition: .3s ease;
  }

  svg {
    fill: white;
  }
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.error {
  color: red;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.sorter {
  position: relative;
}

.sorter::after {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  width: 0.45em;
  top: 45%;
  transform: rotate(-45deg);
  padding-left: -50px;
  vertical-align: middle;
  margin-left: 5px;
}

.sorter[data-order='asc']::after {
  //color: #3f3f3f;
  //border-width: 0.20em 0.20em 0 0;
}

.sorter[data-order='desc']::after {
  transform: rotate(135deg);
  margin-top: -5px;
  //color: #3f3f3f;
  //border-width: 0.20em 0.20em 0 0;
}

.angular-editor-toolbar {
  font: 100 14px/15px Roboto, Arial, sans-serif;
  background-color: #cacdd8 !important;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: -10px;
}

.angular-editor-textarea {
  background-color: #F2F3F6 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
}


.select-heading {
  display: none !important;
}

.select-font {
  display: none !important;
}

.angular-editor-button {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}


.ng-option-disabled.ng-optgroup {
  background: $linear-background;
  color: white !important;
}

a:not([href]) {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.title-extra {
  //max-width: 300px;
  word-break: break-word;
}

ng-select ng-dropdown-panel .ng-option svg-icon {
  fill: gray !important;
}

.ng-select-primary {
  background-color: $primary;

  .ng-value-container {
    color: white
  }

  &.ng-select-opened > .ng-select-container {
    background-color: $primary;
  }
}

.ng-select-secondary {
  background-color: $secondary;

  .ng-value-container {
    color: white
  }

  &.ng-select-opened > .ng-select-container {
    background-color: $secondary;
  }

}

.ng-select-success {
  background-color: $sucess;

  .ng-value-container {
    color: white
  }

  &.ng-select-opened > .ng-select-container {
    background-color: $sucess;
  }
}

.ng-select-grey {
  background-color: grey;

  .ng-value-container {
    color: white
  }

  &.ng-select-opened > .ng-select-container {
    background-color: grey;
  }
}

.ng-select-danger {
  background-color: $danger;

  .ng-value-container {
    color: white
  }

  &.ng-select-opened > .ng-select-container {
    background-color: $danger;
  }
}

.ng-select-invited {
  background-color: #27E3BF;

  .ng-value-container {
    color: white
  }

  &.ng-select-opened > .ng-select-container {
    background-color: #27E3BF;
  }
}

.translate-n50 {
  transform: translate(-50%, -50%);
}

select.form-control-outline-white {
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
}

.logon-dialog .modal-content, .logon-dialog.modal-content {
  min-width: 410px;
}

option:disabled {
  color: #000;
  font-style: italic;
}

.alert {
  &.alert-icon {
    padding-left: 110px;

    svg-icon, i.bi {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 50px;
        height: 50px;
        fill: $gray-500;
      }
    }

    i.bi {
      font-size: 50px;
    }
  }

  p {
    margin-bottom: 5px;
  }
}

.p-check {
  position: relative;
  padding-left: 20px;

  > img {
    position: absolute;
    left: 0;
    top: 7px;
  }
}

.yellow-circl {
  position: absolute;
  top: -30px;
  right: -10px;
}

.text-2 {
  position: absolute;
  top: 74px;
  left: 48px;
  right: 48px;
  font-size: 0.8rem;
  line-height: 1.2;
}

.range-options-small button {
  font-size: 9px !important;
}

.right-position {
  float: right;
}

.h-scroll {
  overflow-x: auto;
}

.word-break {
  word-break: break-all;
}
